import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  contactNumber: "",
  gender: "",
  ageGroup: "",
  lookingFor: [],
  budget: [],
  occasion: "",
  occasionOther: "",
  knowAbout: "",
  knowAboutOther: "",
  timeSpent: "",
  reason: "",
};

const feedbackFormSlice = createSlice({
  name: "feedbackForm",
  initialState,
  reducers: {
    updateField(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
      localStorage.setItem("feedbackForm", JSON.stringify(state));
    },
    resetFeedbackForm(state) {
      Object.assign(state, initialState);
      localStorage.removeItem("feedbackForm");
    },
  },
});

export const { updateField, resetFeedbackForm } = feedbackFormSlice.actions;
export default feedbackFormSlice.reducer;
