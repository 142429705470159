import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Heading, Handler } from '../../components';
import { verticalLogo } from '../../constants';
import { setAgents, loginSuccess } from '../../features/LoginSlice'; // Adjust the path as needed
import './Login.scss';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [agentCode, setAgentCode] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.get('https://us-central1-daton-candere.cloudfunctions.net/Codilar_agent_pwd_api');
      const agents = response.data;

      const matchedAgent = agents.find(
        (agent) =>
          agent.agent_code === agentCode &&
          agent.agent_password === password
      );

      if (matchedAgent) {
        // Update Redux with agent details
        dispatch(setAgents([matchedAgent]));
        dispatch(loginSuccess());

        toast.success('Login successful!');
        navigate('/purchased');
        // navigate('/customer');
      } else {
        toast.error('Invalid Agent Code or Password');
      }
    } catch (error) {
      toast.error('Error fetching agent details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <img src={verticalLogo} alt="Candere Lifestyle Jewellery" className="login-logo" />
      <div className="login--box">
        <Handler />
        <Heading title="Agent Login" />
        <form className="login--form" onSubmit={handleSubmit}>
          <div className="input_group_container">
            <div className="input_group_">
              <input
                className="input_type_text"
                type="text"
                placeholder="Enter Agent Code"
                value={agentCode}
                onChange={(e) => setAgentCode(e.target.value)}
              />
            </div>
          </div>
          <div className="input_group_container">
            <div className="input_group_">
              <input
                className="input_type_text"
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <button
            type="submit"
            className="base_btn btn_md primary_btn login--form-btn"
            disabled={loading}
          >
            {loading ? 'Validating...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
