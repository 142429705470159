import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPurchaseQuestion } from '../../features/purchaseQuestionSlice';
import './Success.scss';

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    navigate('/purchased');
    dispatch(resetPurchaseQuestion());
  }

  return (
    <div className='successPage'>
      <h1 className='successPage-title'>Form Submitted Successfully!</h1>
      <button className='base_btn btn_md primary_btn' onClick={handleSubmit}>Submit Another Response</button>
    </div>
  );
};

export default Success;
