import React, { useEffect, useState } from 'react';
import { FeedbackForm, PurchaseForm } from '../../components';

const Customer = () => {
  const [hasPurchased, setHasPurchased] = useState(null);

  useEffect(() => {
    // Retrieve the selection from localStorage
    const purchaseQuestion = localStorage.getItem('hasPurchased');
    setHasPurchased(purchaseQuestion);
  }, []);

  if (hasPurchased === 'yes') {
    return <PurchaseForm />;
  }

  if (hasPurchased === 'no') {
      return <FeedbackForm />;
  }

  return <div>Loading...</div>;
}

export default Customer
