import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedbackForm: {
    name: "",
    contactNumber: "",
    gender: "",
    ageGroup: "",
    lookingFor: [],
    budget: [],
    occasion: "",
    occasionOther: "",
    knowAbout: "",
    knowAboutOther: "",
    timeSpent: "",
    reason: "",
  },
  purchaseForm: {
    invoiceNumber: "",
    gender: "",
    ageGroup: "",
    occasion: "",
    occasionOther: "",
    knowAbout: "",
    knowAboutOther: "",
    timeSpent: "",
  },
};

const combinedFormSlice = createSlice({
  name: "combinedForm",
  initialState,
  reducers: {
    updateFeedbackField(state, action) {
      const { field, value } = action.payload;
      state.feedbackForm[field] = value;
      localStorage.setItem("combinedForm", JSON.stringify(state));
    },
    updatePurchaseField(state, action) {
      const { field, value } = action.payload;
      state.purchaseForm[field] = value;
      localStorage.setItem("combinedForm", JSON.stringify(state));
    },
    resetCombinedForm(state) {
      Object.assign(state, initialState);
      localStorage.removeItem("combinedForm");
    },
  },
});

export const { updateFeedbackField, updatePurchaseField, resetCombinedForm } = combinedFormSlice.actions;
export default combinedFormSlice.reducer;
