import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasPurchased: "",
};

const purchaseQuestionSlice = createSlice({
  name: "purchaseQuestion",
  initialState,
  reducers: {
    setHasPurchased: (state, action) => {
      state.hasPurchased = action.payload;
    },
    resetPurchaseQuestion: (state) => {
      state.hasPurchased = "";
      localStorage.removeItem("hasPurchased");
    },
  },
});

export const { setHasPurchased, resetPurchaseQuestion } = purchaseQuestionSlice.actions;
export default purchaseQuestionSlice.reducer;
