import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { updateField, resetFeedbackForm } from "../../features/FeedbackFormSlice";
import { updateFeedbackField, resetCombinedForm } from "../../features/combinedFormSlice";
import { Header, Handler, ProgressBar } from '../index'
import '../../custom.scss'
// import './FeedbackForm.scss';

const FeedbackForm = () => {
  const dispatch = useDispatch();
  // const formData = useSelector((state) => state.feedbackForm);
  const formData = useSelector((state) => state.combinedForm.feedbackForm);
  const hasPurchased = useSelector((state) => state.purchaseQuestion.hasPurchased);
  const agents = useSelector((state) => state.login);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();  

  const steps = [
    {
      title: "Personal Details",
      fields: [
        { label: "Name (Optional)", field: "name", type: "text", optional: true },
        {
          label: "Contact Number (Optional)",
          field: "contactNumber",
          type: "text",
          optional: true,
        },
      ],
    },
    {
      title: "Demographics",
      fields: [
        {
          label: "Gender",
          field: "gender",
          type: "radio",
          options: ["Male", "Female", "Couple", "Couple With Child"],
        },
        {
          label: "Age Group",
          field: "ageGroup",
          type: "radio",
          options: ["<18", "18-25", "25-40", "40+"],
        },
      ],
    },
    {
      title: "Preferences",
      fields: [
        {
          label: "What they look for?",
          field: "lookingFor",
          type: "multiselect",
          options: ["Ring", "Earring", "Chain", "Necklace", "Bracelet", "Kada", "Nosepin", "Mangalsutra"],
        },
        {
          label: "Budget",
          field: "budget",
          type: "multiselect",
          options: ["Under 15K", "15k-20K", "20k-30K", "30k-40K", "Above 40K"],
        },
      ],
    },
    {
      title: "Occasion",
      fields: [
        {
          label: "Purpose or Occasion for Visit",
          field: "occasion",
          type: "radio",
          options: ["Gifting", "Self Buy", "Anniversary", "Marriage", "Others"],
          hasOtherOption: true,
          otherField: "occasionOther",
        },
        {
          label: "How do you know about the brand?",
          field: "knowAbout",
          type: "radio",
          options: ["Website", "Social Media", "Through Kalyan Jewellers", "Direct Walk-ins", "Others"],
          hasOtherOption: true,
          otherField: "knowAboutOther",
        },
      ],
    },
    {
      title: "Feedback",
      fields: [
        {
          label: "Consumer Time Spent",
          field: "timeSpent",
          type: "radio",
          options: ["Less than 5 mins", "5-10 mins", "10-20 mins", "20 mins & Above"],
        },
        {
          label: "Reason for Non-Purchase",
          field: "reason",
          type: "radio",
          options: ["Just Browsing", "Will Come Back Later", "Did Not Find The Designs", "Did Not Find Within The Budget"],
        },
      ],
    },
  ];

  const handleNext = () => {
    const stepFields = steps[currentStep].fields;

    for (const field of stepFields) {
      // Specific validation for contactNumber
      if (field.field === "contactNumber" && formData.contactNumber) {
        if (!/^\d{10}$/.test(formData.contactNumber)) {
          toast.error("Please enter a valid 10-digit contact number.");
          return;
        }
      }

      // Skip validation for optional fields (name and contactNumber)
      if (field.optional) continue;

      // Validate required fields
      if (!formData[field.field]) {
        toast.error(`Please fill out ${field.label}`);
        return;
      }

      // Validate multi-select fields
      if (field.type === "multiselect" && (!formData[field.field] || formData[field.field].length === 0)) {
        toast.error(`Please select at least one option for ${field.label}`);
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (event) => {
    // Final validation for all fields except optional ones
    for (const step of steps) {
      for (const field of step.fields) {
        if (field.optional) continue;

        if (!formData[field.field]) {
          toast.error(`Please fill out ${field.label}`);
          return;
        }

        if (field.hasOtherOption && formData[field.field] === "Others" && !formData[field.otherField]) {
          toast.error(`Please specify details for ${field.label}`);
          return;
        }
      }
    }

    // Validate contactNumber if provided
    if (formData.contactNumber && !/^\d{10}$/.test(formData.contactNumber)) {
      toast.error("Please enter a valid 10-digit contact number.");
      return;
    }

    event.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess(false);

    const agentCode = agents?.agents?.[0]?.agent_code;

    const formDataWithAgentCode = {
      ...formData,
      agentCode: agentCode,
      hasPurchased: hasPurchased,
    };

    try {
      const response = await axios.post("https://us-central1-daton-candere.cloudfunctions.net/Store_app_data", formDataWithAgentCode);

      if (response.status === 200) {
        setSuccess(true);
        dispatch(resetCombinedForm());
        toast.success("Form submitted successfully!");
        navigate('/success');
      } else {
        setError("Failed to submit the feedback. Please try again.");
        toast.error("Failed to submit the feedback. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while submitting the feedback.");
      toast.error("An error occurred while submitting the feedback.");
    } finally {
      setIsSubmitting(false);
      setCurrentStep(0);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#d9dbe9",
      "&:hover": { borderColor: "#a0a3bd" },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#8ddae3",
    }),
    // menu: (base) => ({
    //   ...base,
    //   maxHeight: "150px",
    //   overflowY: "auto",
    //   zIndex: 5,
    // }),
  };

  return (
    <>
      <Header />
      <div className="customForm">
        <h1 className="customForm-head">Feedback Form</h1>
        <div className="customForm--box">
          <ProgressBar progress={(currentStep / steps.length) * 100} />
          <Handler />
          {/* <h2>{steps[currentStep].title}</h2> */}
          {steps[currentStep].fields.map((field) => (
            <div className="customForm--box-content" key={field.field}>
              <div className="input_group_container">
                <div className="input_group_">
                  <label className="input_label">{field.label}</label>
                  {field.type === "text" && (
                    <input
                      className="input_type_text"
                      type="text"
                      value={formData[field.field] || ""}
                      onChange={(e) =>
                        dispatch(updateFeedbackField({ field: field.field, value: e.target.value }))
                      }
                    />
                  )}
                  {field.type === "radio" && (
                    <div className="radio-inline">
                      {field.options.map((option) => (
                        <label className="radio--wrap" key={option}>
                          {option}
                          <input
                            type="radio"
                            name={field.field}
                            className="custom-radio"
                            value={option}
                            checked={formData[field.field] === option}
                            onChange={() => {
                              dispatch(updateFeedbackField({ field: field.field, value: option }));
                              if (field.hasOtherOption && option !== "Others") {
                                // Clear the "Others" field if a different option is selected
                                dispatch(updateFeedbackField({ field: field.otherField, value: "" }));
                              }
                            }}
                            disabled={isSubmitting}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  )}
                  {field.hasOtherOption &&
                    formData[field.field] === "Others" && (
                      <input
                        className="input_type_text"
                        type="text"
                        placeholder={`Specify other ${field.label.toLowerCase()}`}
                        value={formData[field.otherField] || ""}
                        onChange={(e) =>
                          dispatch(updateFeedbackField({ field: field.otherField, value: e.target.value }))
                        }
                        disabled={isSubmitting}
                        style={{marginTop: '12px'}}
                    />
                  )}
                  {field.type === "multiselect" && (
                    <div className="custome_select">
                      <Select
                        styles={customStyles}
                        isMulti
                        options={field.options.map((option) => ({ value: option, label: option }))}
                        value={formData[field.field]?.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        onChange={(selectedOptions) =>
                          dispatch(
                            updateFeedbackField({
                              field: field.field,
                              value: selectedOptions ? selectedOptions.map((option) => option.value) : [],
                            })
                          )
                        }
                        placeholder={`Select ${field.label.toLowerCase()}`}
                        disabled={isSubmitting}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="customForm--action">
            {currentStep > 0 && (
              <button
                className="customForm--action-child feedbackForm--action-back base_btn btn_md secondary_btn"
                onClick={handleBack}
                disabled={isSubmitting}
              >
                Back
              </button>
            )}
            {currentStep < steps.length - 1 && (
              <button
                className="customForm--action-child feedbackForm--action-next base_btn btn_md primary_btn"
                onClick={handleNext}
                disabled={isSubmitting}
              >
                Next
              </button>
            )}
            {currentStep === steps.length - 1 && (
              <button type="submit"
                className="customForm--action-child feedbackForm--action-submit base_btn btn_md primary_btn"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                 {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackForm;
