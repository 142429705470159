import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHasPurchased } from "../../features/purchaseQuestionSlice";
import { Header, Handler } from '../index';
import './PurchaseQuestion.scss';

const PurchaseQuestion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        dispatch(setHasPurchased(selectedValue));
        localStorage.setItem('hasPurchased', selectedValue);
        navigate('/customer');
    };

    return (
        <>
            <Header />
            <div className='customForm'>
                <div className='customForm--box'>
                    <Handler />
                    <div className="customForm--box-content">
                        <div className="input_group_container">
                            <div className="input_group_">
                                <label className="input_label">Did the customer make a purchase?</label>
                                <div className="radio-inline">
                                    <label className="radio--wrap"> Yes
                                        <input
                                        type="radio"
                                        name="purchase"
                                        className="custom-radio"
                                        value="yes"
                                        onChange={handleOptionChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="radio--wrap"> No
                                        <input
                                        type="radio"
                                        name="purchase"
                                        className="custom-radio"
                                        value="no"
                                        onChange={handleOptionChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchaseQuestion
