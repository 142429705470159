import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { updateField, resetPurchaseForm } from "../../features/PurchaseFormSlice";
import { updatePurchaseField, resetCombinedForm } from "../../features/combinedFormSlice";
import { Header, Handler, ProgressBar } from '../index';
import '../../custom.scss';

const PurchaseForm = () => {
  const dispatch = useDispatch();
  // const formData = useSelector((state) => state.purchaseForm);
  const formData = useSelector((state) => state.combinedForm.purchaseForm);
  const hasPurchased = useSelector((state) => state.purchaseQuestion.hasPurchased);
  const agents = useSelector((state) => state.login);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();  

  const steps = [
    {
      title: "Personal Details",
      fields: [
        { label: "Invoice Number", field: "invoiceNumber", type: "text", optional: false },
      ],
    },
    {
      title: "Demographics",
      fields: [
        {
          label: "Gender",
          field: "gender",
          type: "radio",
          options: ["Male", "Female", "Couple", "Couple With Child"],
        },
        {
          label: "Age Group",
          field: "ageGroup",
          type: "radio",
          options: ["<18", "18-25", "25-40", "40+"],
        },
      ],
    },
    {
      title: "Occasion",
      fields: [
        {
          label: "Purpose or Occasion for Visit",
          field: "occasion",
          type: "radio",
          options: ["Gifting", "Self Buy", "Anniversary", "Marriage", "Others"],
          hasOtherOption: true,
          otherField: "occasionOther",
        },
        {
          label: "How do you know about the brand?",
          field: "knowAbout",
          type: "radio",
          options: ["Website", "Social Media", "Through Kalyan Jewellers", "Direct Walk-ins", "Others"],
          hasOtherOption: true,
          otherField: "knowAboutOther",
        },
      ],
    },
    {
      title: "Feedback",
      fields: [
        {
          label: "Consumer Time Spent",
          field: "timeSpent",
          type: "radio",
          options: ["Less than 5 mins", "5-10 mins", "10-20 mins", "20 mins & Above"],
        },
      ],
    },
  ];

  const handleNext = () => {
    const stepFields = steps[currentStep].fields;

    for (const field of stepFields) {
      if (field.field === "invoiceNumber" && (!formData.invoiceNumber || formData.invoiceNumber.trim() === "")) {
        toast.error("Invoice Number is mandatory.");
        return;
      }

      if (!field.optional && !formData[field.field]) {
        toast.error(`Please fill out ${field.label}`);
        return;
      }

      if (field.hasOtherOption && formData[field.field] === "Others" && (!formData[field.otherField] || formData[field.otherField].trim() === "")) {
        toast.error(`Please specify ${field.label}`);
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Final validation check for all steps
    const validateAllSteps = () => {
      for (let step = 0; step < steps.length; step++) {
        const stepFields = steps[step].fields;
  
        for (const field of stepFields) {
          if (field.field === "invoiceNumber" && (!formData.invoiceNumber || formData.invoiceNumber.trim() === "")) {
            toast.error("Invoice Number is mandatory.");
            setCurrentStep(step); // Navigate to the invalid step
            return false;
          }
  
          if (!field.optional && !formData[field.field]) {
            toast.error(`Please fill out ${field.label}`);
            setCurrentStep(step); // Navigate to the invalid step
            return false;
          }
  
          if (field.hasOtherOption && formData[field.field] === "Others" && (!formData[field.otherField] || formData[field.otherField].trim() === "")) {
            toast.error(`Please specify ${field.label}`);
            setCurrentStep(step); // Navigate to the invalid step
            return false;
          }
        }
      }
      return true;
    };
  
    // Stop submission if final validation fails
    if (!validateAllSteps()) {
      return;
    }
  
    setIsSubmitting(true);
    setError("");
  
    const agentCode = agents?.agents?.[0]?.agent_code;
  
    const formDataWithAgentCode = {
      ...formData,
      agentCode: agentCode,
      hasPurchased: hasPurchased,
    };
  
    try {
      const response = await axios.post("https://us-central1-daton-candere.cloudfunctions.net/Store_app_data", formDataWithAgentCode);
  
      if (response.status === 200) {
        dispatch(resetCombinedForm());
        toast.success("Form submitted successfully!");
        navigate("/success");
      } else {
        toast.error("Failed to submit the feedback. Please try again.");
      }
    } catch (err) {
      toast.error("An error occurred while submitting the feedback.");
    } finally {
      setIsSubmitting(false);
      setCurrentStep(0);
    }
  };  

  return (
    <>
      <Header />
      <div className="customForm">
        <h1 className="customForm-head">Feedback Form</h1>
        <div className="customForm--box">
          <ProgressBar progress={(currentStep / steps.length) * 100} />
          <Handler />
          {steps[currentStep].fields.map((field) => (
            <div className="customForm--box-content" key={field.field}>
              <div className="input_group_container">
                <div className="input_group_">
                  <label className="input_label">{field.label}</label>
                  {field.type === "text" && (
                    <input
                      className="input_type_text"
                      type="text"
                      value={formData[field.field] || ""}
                      onChange={(e) =>
                        dispatch(updatePurchaseField({ field: field.field, value: e.target.value }))
                      }
                    />
                  )}
                  {field.type === "radio" && (
                    <div className="radio-inline">
                      {field.options.map((option) => (
                        <label className="radio--wrap" key={option}>
                          {option}
                          <input
                            type="radio"
                            name={field.field}
                            className="custom-radio"
                            value={option}
                            checked={formData[field.field] === option}
                            onChange={() => {
                              dispatch(updatePurchaseField({ field: field.field, value: option }));
                              if (field.hasOtherOption && option !== "Others") {
                                dispatch(updatePurchaseField({ field: field.otherField, value: "" })); // Clear "Other" field
                              }
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                      {field.hasOtherOption &&
                        formData[field.field] === "Others" && (
                          <input
                            className="input_type_text"
                            type="text"
                            placeholder={`Specify other ${field.label.toLowerCase()}`}
                            value={formData[field.otherField] || ""}
                            onChange={(e) =>
                              dispatch(updatePurchaseField({ field: field.otherField, value: e.target.value }))
                            }
                            disabled={isSubmitting}
                            style={{marginTop: '12px'}}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="customForm--action">
            {currentStep > 0 && (
              <button
                className="customForm--action-child feedbackForm--action-back base_btn btn_md secondary_btn"
                onClick={handleBack}
                disabled={isSubmitting}
              >
                Back
              </button>
            )}
            {currentStep < steps.length - 1 && (
              <button
                className="customForm--action-child feedbackForm--action-next base_btn btn_md primary_btn"
                onClick={handleNext}
                disabled={isSubmitting}
              >
                Next
              </button>
            )}
            {currentStep === steps.length - 1 && (
              <button
                className="customForm--action-child feedbackForm--action-submit base_btn btn_md primary_btn"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseForm;
