import { configureStore } from "@reduxjs/toolkit";
import loginReducer from './features/LoginSlice';
import feedbackFormReducer from './features/FeedbackFormSlice';
import purchaseFormReducer from './features/PurchaseFormSlice';
import purchaseQuestionReducer from "./features/purchaseQuestionSlice";
import combinedFormReducer from "./features/combinedFormSlice";

export const store = configureStore({
    reducer: {
        login: loginReducer,
        feedbackForm: feedbackFormReducer,
        purchaseForm: purchaseFormReducer,
        purchaseQuestion: purchaseQuestionReducer,
        combinedForm: combinedFormReducer
    }
})