import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoiceNumber: "",
    gender: "",
    ageGroup: "",
    occasion: "",
    occasionOther: "",
    knowAbout: "",
    knowAboutOther: "",
    timeSpent: "",
};

const purchaseFormSlice = createSlice({
  name: "purchaseForm",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
      localStorage.setItem("purchaseForm", JSON.stringify(state));
    },
    resetPurchaseForm: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem("purchaseForm");
    },
  },
});

export const { updateField, resetPurchaseForm } = purchaseFormSlice.actions;
export default purchaseFormSlice.reducer;
